(function ($) {
  Drupal.behaviors.clinique_sticky_bar = {
    attach: function (context, settings) {
      $('#sticky-bar', context).once('sticky', function () {
        // adjustment needed to handle the CMS toolbar if present
        var e = Drupal.cl.getElements();
        var adjust = e.body.hasClass('toolbar') && e.top.css('position') == 'fixed' ? // is it using a toolbar?
          $('#toolbar').length !== 0 ? $('#toolbar').height() : 100 // yes - what height?
          : 0; // no - zero
        var sticky_marker = $('<div class="sticky-marker"></div>');

        // place the sticky_marker at the spot where the regular nav should switch to the sticky nav
        sticky_marker.css({
          'position': 'absolute',
          'top': (72 + adjust) + 'px'
        });
        // add it to the main area
        sticky_marker.prependTo($('body', context));

        var $header = $('#top', context),
          $sticky = $(this),
          $top_wrapper = $('#top_wrapper', context),
          headerHeight = $header.outerHeight(true);
        var $cartOverlay = $('.block-template-gnav-cart-v1 .gnav-drop-down-v1 .dd-content', context);

        // append the sticky nav to the main area
        $(this).prependTo($header.parent());

        sticky_marker.waypoint(function (direction) {
          // recheck needed adjustment each time... (due to new cms toggle-able toolbar)
          // adjust = (e.body.hasClass('toolbar') && e.top.css('position') == 'fixed')?
          adjust = e.body.hasClass('toolbar') ?
            $('#toolbar').length !== 0 ? $('#toolbar').height() : 100
            : 0;
          sticky_marker.css({
            'top': (72 + adjust) + 'px'
          });
          if (direction === 'down') {
            $header.css({
              'position': 'fixed',
              'height': '115px',
              'top': (adjust - 68) + 'px',
              'overflow': 'visible'
            });
            // add height to the wrapper to prevent jumpiness (keeps document length the same)
            $top_wrapper.height(115);
            $sticky.show().animate({ 'top': adjust + 'px' }, 0);
            Drupal.behaviors.clinique_sticky_bar.activateStickyElements(context, $cartOverlay, adjust);
          } else if (direction === 'up') {
            $sticky.hide();
            $sticky.css({ 'top': '-48px' });
            $header.css({
              'position': 'relative',
              'height': '115px',
              'top': 0 + 'px',
              'overflow': 'visible'
            });
            $top_wrapper.height('');
            $header.show().animate(0);
            Drupal.behaviors.clinique_sticky_bar.deactivateStickyElements(context, $cartOverlay, adjust);
          }
        });
      });
    },
    activateStickyElements: function (context, cartOverlay, height_adjustment) {
      // hook up search
      $('.search-mobile', context).once('sticky-search').on('click', function() {
        if ($(this).hasClass('active')) {
          $(this).prev().animate({
            width: 0
          }, {
            duration: 500,
            progress: function () {
              $(window).resize();
            },
            complete: function () {
              $(this).next().removeClass('active');
              $(window).resize();
            }
          });
        } else {
          $(this).addClass('active');
          $(this).prev().animate({
            width: 260
          }, {
            duration: 1000,
            progress: function () {
              $(window).resize();
            },
            complete: function () {
              $(window).resize();
            }
          });
        }

        return false;
      });

      $('#perlgem-search-form', context).prependTo($('.search-mobile-form', context));
      $('#typeahead-wrapper', context).addClass('typeahead-sticky');

      // hook up cart
      $('.block-template-gnav-cart-v1 .gnav-drop-down-v1 .dd-content', context).css('margin-top', '44px');
      $('.shopping-bag', context)
        .hover(function (evt) {
          if ($('#sticky-bar em[data-pg-prop="items_count"]', context).html() !== '') {
            $('.block-template-gnav-cart-v1 .gnav-drop-down-v1 .dd-content', context).addClass('active');
          }
        }, function () {
          $('.block-template-gnav-cart-v1 .gnav-drop-down-v1 .dd-content', context).removeClass('active');
        }).on('clickoutside touchstartoutside', function (event) {
          $('.block-template-gnav-cart-v1 .gnav-drop-down-v1 .dd-content', context).removeClass('active');
        })
        .on('click', function(evt) {
          $('.block-template-gnav-cart-v1 .gnav-drop-down-v1.event-hover', context).trigger('click');
        });

      // hook up help // block-template-help-menu-v1 help-menu-v1
      $('.block-template-help-menu-v1 .gnav-drop-down-v1 .dd-content', context).css('margin-top', '44px'); // adjusted for new -68px #top
      $('#sticky-bar .live-chat a', context).on('click', function(e) {
        e.preventDefault();
        if ($('.block-template-help-menu-v1 .gnav-drop-down-v1 .dd-content.active', context).length === 0) {
          setTimeout(function () {
            $('.block-template-help-menu-v1 .gnav-drop-down-v1 .dd-content', context).addClass('active');
          }, 200);
        }
      });

      // hook up nav elements if not SPP, MPP, Power Couple, or 3 Step
      if (!$('body').hasClass('pc_spp_view')) {
        $('#sticky_bar_content', context).once(function () {
          if ($(this).children().length === 0) { // only if there isn't other content from the exception mentioned above
            $(this).append($('#navigation ul.mm-menu:first', context).clone());
            $('ul.mm-menu a', $(this)).addClass('sticky_nav_option');
            $('ul.mm-menu a', $(this)).on('click', function(e) {
              var $li = $(e.target).parents('li[block-display]');

              if ($li.hasClass('no-subnav')) {
                window.location.href = $li.find('a').attr('href');

                return;
              } else {
                e.preventDefault();
              }
              $('ul.mm-menu li', $(this)).removeClass('active');
              var bdis = $li.attr('block-display');

              $('#navigation li[block-display="' + bdis + '"]', context).trigger('click');
              $li.addClass('active');
            });

            // The following creates a "More" menu when page is narrow
            var stickyBar = $('#sticky-bar');
            var menu = $('.mm-menu', stickyBar);
            var moreMenu = $('.item-more', menu);
            var logoWidth = parseInt($('.site-logo', stickyBar).outerWidth(true));
            var moreMenuWidth = 150;

            $('li', menu).each(function () {
              var width = parseInt($(this).attr('width'));

              moreMenuWidth = moreMenuWidth + width;
            });

            var firstItemPos = $('li:first', menu),
              firstItemPos = firstItemPos.length ? firstItemPos.position().top : 0;
            // dynamically populate the More menu
            var buildMoreMenu = function () {
              moreMenu.removeClass('open');
              var navRightWidth = parseInt($('.tertiary-nav', stickyBar).outerWidth(true));
              var moreMenuContent = $('.more-content', moreMenu);
              var lastItemPos = $('li:visible', menu).last();

              if (lastItemPos.length) {
                lastItemPos = lastItemPos.position().top;

                var lastElem = $('li:visible', menu).not('.item-more').last();

                // if a menu item breaks to the next line...
                if (lastItemPos > firstItemPos) {
                  // hide the main menu item, and prepend a clone to the More menu
                  moreMenuWidth = moreMenuWidth - parseInt(lastElem.attr('width'));
                  lastElem.hide().clone(true).show().prependTo(moreMenuContent);
                  // show the More menu
                  if ($.trim(moreMenuContent.html()).length) {
                    moreMenu.show();
                  }
                  // check again
                  buildMoreMenu();
                }
              }

              // replace hidden menu items if there is room, removing them from More menu
              var availableSpace = parseInt($('#sticky_bar_content').outerWidth(true)) - (moreMenuWidth + navRightWidth + logoWidth);
              var firstItemWidth = parseInt($('li:first', moreMenu).attr('width'));

              if (availableSpace > firstItemWidth) {
                // show the first-hidden menu item
                var item = $('> li', menu).not(':visible').not('.item-more').first();

                moreMenuWidth = moreMenuWidth + parseInt(item.attr('width'));
                item.show();
                // remove it from the More menu
                $('li:first', moreMenu).remove();
              }

              // hide More menu if empty
              if (!$.trim(moreMenuContent.html()).length) {
                moreMenu.hide();
              }
            };

            // check initial state of the menu
            buildMoreMenu();

            $(window).resize(function () {
              buildMoreMenu();
            }).resize();
          }
        });
      }

      // hook up iPad 'more' link
      $('.item-more', context).once().on('click', function(e) {
        $(this).toggleClass('open');
      });
    },
    deactivateStickyElements: function (context, cartOverlay, height_adjustment) {
      $('.block-template-gnav-cart-v1 .gnav-drop-down-v1 .dd-content', context)
        .css('margin-top', '0');
      $('.search-mobile-form form', context).prependTo($('.gnav-search-v1', context));
      $('#typeahead-wrapper', context).removeClass('typeahead-sticky');
      $('.block-template-help-menu-v1 .gnav-drop-down-v1 .dd-content', context)
        .css('margin-top', '0');
    },
    addContent: function (html_content) {
      $('#sticky_bar_content').html(html_content);
    }
  };
})(jQuery);
